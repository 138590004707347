import { SearchParams, FilterParams } from './types';

export const getDestinationFinderQueryParams = (
  searchParams: SearchParams,
  filterParams: FilterParams
): URLSearchParams => {
  const params = new URLSearchParams({
    date: searchParams.departureDate,
    departure_airports: searchParams.departureAirports,
    duration: searchParams.duration,
  });
  if (searchParams.destinationCountry)
    params.set('country', searchParams.destinationCountry);
  if (filterParams.temperature)
    params.set('temperature', filterParams.temperature);
  if (filterParams.maxFlightTime)
    params.set('max_flight_time', filterParams.maxFlightTime);
  if (filterParams.budget) params.set('budget', filterParams.budget);
  if (filterParams.categories)
    params.set('categories', filterParams.categories.join(','));

  return params;
};

export const sanitizeFilterParams = (
  filterParams: FilterParams
): FilterParams => {
  const sanitizedFilterParams = { ...filterParams };

  (Object.keys(sanitizedFilterParams) as Array<keyof FilterParams>).forEach(
    (key) => {
      const value = sanitizedFilterParams[key];
      if (value === '0' || (Array.isArray(value) && value.length === 0)) {
        delete sanitizedFilterParams[key];
      }
    }
  );
  return sanitizedFilterParams;
};
