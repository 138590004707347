import { StateCreator } from 'zustand';
import { Slices } from '../types';
import {
  AdvertisingSlice,
  InlineAd,
  RibbonAd,
  AdvertisingConfig,
  ClickTripzAdSettings,
  OverlayAdvertiser,
} from './types';
import { getQueryParamsWithSessionIdAndPageRequestId } from '../utils/cookies';
import { OVERLAY_TIMESTAMP_LOCAL_STORAGE_KEY } from './constants';
import {
  calculateIsIntervalPassed,
  getConditionalUnitsQueryParams,
  getClickTripzAdQueryParams,
  redirectCurrentTab,
  getBackTabClickTripzAdvertisersQueryParams,
} from './utils';
import { fetchJson } from '../utils/fetchJson';

export const createAdvertisingSlice =
  (config: AdvertisingConfig): StateCreator<Slices, [], [], AdvertisingSlice> =>
  (set, get) => ({
    config,
    inlineAdSlot0: null,
    inlineAdSlot1: null,
    ribbonAd: null,
    ribbonAdHeight: 0,
    clickTripzBackTabSettings: null,
    overlaySettings: null,
    overlayAdvertisers: [],
    showRibbonAd: false,
    showOverlay: false,
    isMediaAlphaScriptLoaded: false,
    closeOverlay: () => {
      set((state) => ({
        ...state,
        advertising: { ...state.advertising, showOverlay: false },
      }));
    },
    setShowRibbonAd: (showRibbonAd) => {
      if (showRibbonAd === get().advertising.showRibbonAd) return;
      set((state) => ({
        ...state,
        advertising: { ...state.advertising, showRibbonAd: showRibbonAd },
      }));
    },
    setRibbonAdHeight: (height) => {
      set((state) => ({
        ...state,
        advertising: { ...state.advertising, ribbonAdHeight: height },
      }));
    },
    _fetchClickTripzAdvertisersByUrl: async (
      url: string
    ): Promise<OverlayAdvertiser[]> => {
      try {
        const response = await fetch(url);
        const responseText = await response.text();
        const parsedResponse: { data: { advertisers: OverlayAdvertiser[] } } =
          JSON.parse(responseText.slice(1, -1));

        if (parsedResponse.data && parsedResponse.data.advertisers) {
          return parsedResponse.data.advertisers;
        }

        return [];
      } catch (e) {
        return [];
      }
    },
    loadClickTripzAdSettings: async (type, device) => {
      const searchParams = get().searchResults.searchParams;
      if (!searchParams) return;

      try {
        const queryParams = getClickTripzAdQueryParams(
          searchParams,
          get().utmParams,
          config.site,
          device,
          getQueryParamsWithSessionIdAndPageRequestId()
        );
        const settings = await fetchJson<ClickTripzAdSettings>(
          `${config.advertisingApi}/v1/${type}?${queryParams.toString()}`
        );

        set((state) => ({
          ...state,
          advertising:
            type === 'overlay'
              ? { ...state.advertising, overlaySettings: settings }
              : { ...state.advertising, clickTripzBackTabSettings: settings },
        }));
        return settings;
      } catch (e) {
        return;
      }
    },
    loadOverlay: async (device: 'mobile' | 'tablet' | 'desktop') => {
      try {
        const overlaySettings =
          await get().advertising.loadClickTripzAdSettings('overlay', device);
        if (!overlaySettings) return;

        const previousTimestamp =
          Number(localStorage.getItem(OVERLAY_TIMESTAMP_LOCAL_STORAGE_KEY)) ||
          0;
        const resultsLength = get().searchResults.results.length;
        if (
          !overlaySettings.metadata.enabled ||
          !calculateIsIntervalPassed(
            previousTimestamp,
            Date.now(),
            overlaySettings.metadata.interval,
            resultsLength > 0 ? overlaySettings.metadata.delay : 0
          )
        ) {
          return;
        }

        const advertisersFromUrls = await Promise.all(
          overlaySettings.advertisersUrls.map((url) =>
            get().advertising._fetchClickTripzAdvertisersByUrl(url)
          )
        );
        const advertisers = advertisersFromUrls
          .reduce((acc, advertisers) => acc.concat(advertisers), [])
          .sort((advertiserA, advertiserB) => {
            const cpcA = advertiserA.cpc;
            const cpcB = advertiserB.cpc;

            return cpcB - cpcA;
          });

        set((state) => ({
          ...state,
          advertising: {
            ...state.advertising,
            overlaySettings,
            overlayAdvertisers: advertisers,
          },
        }));

        const triggerOverlay = () => {
          if (!get().advertising.overlayAdvertisers.length) return;
          set((state) => ({
            ...state,
            advertising: {
              ...state.advertising,
              showOverlay: true,
            },
          }));
          localStorage.setItem(
            OVERLAY_TIMESTAMP_LOCAL_STORAGE_KEY,
            `${Date.now()}`
          );
        };

        if (!resultsLength) {
          triggerOverlay();
        } else {
          setTimeout(triggerOverlay, overlaySettings.metadata.delay);
        }

        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    loadConditionalUnits: async (conditionalUnitsOptions) => {
      const config = get().advertising.config;
      try {
        const queryParams = getConditionalUnitsQueryParams(
          conditionalUnitsOptions,
          config.site,
          getQueryParamsWithSessionIdAndPageRequestId(),
          new URLSearchParams(window.location.search)
        );
        const data = await fetchJson<{
          inline?: { 0?: InlineAd; 1?: InlineAd };
          ribbon?: { 0?: RibbonAd };
        }>(
          `${config.advertisingApi}/v2/inline/units?${queryParams.toString()}`
        );
        set((state) => ({
          ...state,
          advertising: {
            ...state.advertising,
            inlineAdSlot0: data.inline?.['0'] || null,
            inlineAdSlot1: data.inline?.['1'] || null,
            ribbonAd: data.ribbon?.['0'] || null,
          },
        }));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    getClickLink: (ad, options) => {
      const config = get().advertising.config;
      if (options.target === 'package_search') {
        const searchParams = options.searchParams;
        const queryParams = new URLSearchParams({
          ...Object.fromEntries(getQueryParamsWithSessionIdAndPageRequestId()),
          adults: searchParams.adults,
          childAges: searchParams.childAges,
          country: searchParams.destinationCountry,
          area: searchParams.destinationArea,
          ...(searchParams.destinationRegion
            ? { region: searchParams.destinationRegion }
            : {}),
          departureAirports: searchParams.departureAirports,
          departureDate: searchParams.departureDate,
          duration: searchParams.duration,
          inlineUnitUuid: ad.uuid,
        });
        return `${
          config.brokerApi
        }/v1/packages/search/click?${queryParams.toString()}`;
      } else {
        const queryParams = new URLSearchParams({
          ...Object.fromEntries(getQueryParamsWithSessionIdAndPageRequestId()),
          uuid: ad.uuid,
          departureDate: options.searchParams.pickUpDateTime.split('T')[0],
          returnDate: options.searchParams.dropOffDateTime.split('T')[0],
        });
        return `${
          config.advertisingApi
        }/v1/inline/click?${queryParams.toString()}`;
      }
    },
    redirectWithClickTripzBackTab: async (
      nextUrl,
      nextSearchParams,
      destinationName: string,
      device
    ) => {
      const {
        config,
        clickTripzBackTabSettings,
        _fetchClickTripzAdvertisersByUrl,
      } = get().advertising;
      const previousTimestamp =
        Number(
          localStorage.getItem(
            config.clickTripzBackTab.timestampLocalStorageKey
          )
        ) || 0;
      if (
        !clickTripzBackTabSettings ||
        !clickTripzBackTabSettings.metadata.enabled ||
        !calculateIsIntervalPassed(
          previousTimestamp,
          Date.now(),
          clickTripzBackTabSettings.metadata.interval
        ) ||
        !window.open(nextUrl, '_blank')
      ) {
        redirectCurrentTab(nextUrl);
        return;
      }

      const queryParams = getBackTabClickTripzAdvertisersQueryParams(
        nextSearchParams,
        get().utmParams,
        destinationName,
        config.clickTripzBackTab.desktopPlacementId,
        config.clickTripzBackTab.mobilePlacementId,
        device,
        config.clickTripzBackTab.siteId
      );
      const advertisers = await _fetchClickTripzAdvertisersByUrl(
        `${config.clickTripzApi}?${queryParams.toString()}`
      );
      if (advertisers.length > 0) {
        localStorage.setItem(
          config.clickTripzBackTab.timestampLocalStorageKey,
          `${Date.now()}`
        );
        redirectCurrentTab(advertisers[0].link);
      }
    },
    loadMediaAlpha: () => {
      const { isMediaAlphaScriptLoaded, config } = get().advertising;
      if (
        isMediaAlphaScriptLoaded ||
        !config.mediaAlphaScriptUrl ||
        !config.mediaAlphaBackTab?.enabled
      )
        return;
      const mediaAlphaScript = document.createElement('script');
      mediaAlphaScript.src = config.mediaAlphaScriptUrl;
      mediaAlphaScript.async = true;
      document.head.appendChild(mediaAlphaScript);
      set((state) => ({
        ...state,
        advertising: { ...state.advertising, isMediaAlphaScriptLoaded: true },
      }));
    },
    redirectWithMediaAlphaBackTab: (nextUrl, carHireData, device) => {
      const { config } = get().advertising;
      if (!config.mediaAlphaBackTab || !config.mediaAlphaBackTab.enabled) {
        redirectCurrentTab(nextUrl);
        return;
      }
      const previousTimestamp =
        Number(
          localStorage.getItem(
            config.mediaAlphaBackTab.timestampLocalStorageKey
          )
        ) || 0;
      if (
        !('MediaAlphaExchange__leaveBehind' in window) ||
        !calculateIsIntervalPassed(
          previousTimestamp,
          Date.now(),
          config.mediaAlphaBackTab.intervalMs
        )
      ) {
        redirectCurrentTab(nextUrl);
        return;
      }
      window.MediaAlphaExchange = {
        data: carHireData,
        locale: 'en',
        sub_1: 'test sub id',
        type: 'ad_unit',
        version: '17',
        leaveBehind: {
          placement_id:
            device === 'desktop'
              ? config.mediaAlphaBackTab.desktopPlacementId
              : config.mediaAlphaBackTab.mobilePlacementId,
        },
      };
      localStorage.setItem(
        config.mediaAlphaBackTab.timestampLocalStorageKey,
        `${Date.now()}`
      );
      window.MediaAlphaExchange__leaveBehind({
        type: 'trailing',
        url: nextUrl,
      });
    },
  });
