// import React from 'react';
// import whyDidYouRender from '@welldone-software/why-did-you-render';
import { withContextProviders } from '@supersonic/components';
import config from './config';
import './src/global.css';

export const wrapRootElement = ({ element }) =>
  withContextProviders({ element, config });

// Uncomment the following code to enable whyDidYouRender in development - it's useful for debugging React performance issues
// https://github.com/welldone-software/why-did-you-render
// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//     });
//   }
// };
