import { StateCreator } from 'zustand';
import { Slices } from '../types';
import { UtmParamsConfig, UtmParamsSlice } from './types';
import { getCookie, setCookie, setSessionCookie } from '../utils/cookies';
import { ADAL_COOKIE_NAME, ADAL_COPY_COOKIE_NAME, CONFIG } from './constants';
import {
  calculateUtmParamValue,
  delay,
  isUtmValueRecognized,
  parseAdalValue,
} from './utils';

export const createUtmParamsSlice =
  (config: UtmParamsConfig): StateCreator<Slices, [], [], UtmParamsSlice> =>
  (set, get) => ({
    config,
    campaign: null,
    medium: null,
    source: null,
    content: null,
    _initWithIcelollyLogic: async () => {
      // TODO - This logic on client side is temporary. Once HOT-4309 is implemented,
      //  we should simply read UTM values from cookies as we do for TSM
      const setUtmParams = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const rawAdalValue = getCookie(ADAL_COOKIE_NAME);
        const rawAdalCopyValue = getCookie(ADAL_COPY_COOKIE_NAME);
        const shouldUseAdalData = rawAdalValue !== rawAdalCopyValue;
        const shouldPrioritiseAdalData =
          shouldUseAdalData && !!rawAdalCopyValue;
        const adalData = rawAdalValue ? parseAdalValue(rawAdalValue) : null;

        if (shouldUseAdalData && rawAdalValue) {
          setCookie(ADAL_COPY_COOKIE_NAME, rawAdalValue);
        }

        CONFIG.forEach((param) => {
          const currentCookieValue = getCookie(param.cookieName);
          const paramValue = calculateUtmParamValue(
            param,
            currentCookieValue,
            queryParams,
            adalData,
            shouldUseAdalData,
            shouldPrioritiseAdalData
          );

          setSessionCookie(param.cookieName, paramValue);
          set((state) => ({
            ...state,
            utmParams: { ...state.utmParams, [param.name]: paramValue },
          }));
        });
      };

      setUtmParams();

      let isAdalCookieAvailable = !!getCookie(ADAL_COOKIE_NAME);
      const shouldPoolAdalCookie =
        !isAdalCookieAvailable &&
        CONFIG.some(
          (param) => !isUtmValueRecognized(getCookie(param.cookieName))
        );

      if (shouldPoolAdalCookie) {
        // Pool adal cookies once a second until it's available
        while (!isAdalCookieAvailable) {
          // eslint-disable-next-line no-await-in-loop
          await delay(1000);
          isAdalCookieAvailable = !getCookie(ADAL_COOKIE_NAME);
        }

        // Set UTM params again once adal cookie is available
        setUtmParams();
      }
    },
    _initWithTravelsupermarketLogic: async () => {
      set((state) => ({
        ...state,
        utmParams: {
          ...state.utmParams,
          source: getCookie('utm-source'),
          content: getCookie('utm-content'),
          campaign: getCookie('utm-campaign'),
          medium: getCookie('utm-medium'),
        },
      }));
    },
    init: async () => {
      const logic = get().utmParams.config.logic;
      if (logic === 'icelolly') {
        get().utmParams._initWithIcelollyLogic();
      } else if (logic === 'travelsupermarket') {
        get().utmParams._initWithTravelsupermarketLogic();
      }
    },
  });
