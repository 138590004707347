import { DestinationFinderQuestions } from './types';

export const DEFAULT_QUERY_PARAMS = {
  departureAirports: 'BRS+EMA+LGW+GLA+LBA+LTN+MAN+STN',
  departureDate: '+30',
  duration: '7',
  passengers: '2',
  country: undefined,
};

export const DESTINATION_FINDER_QUESTIONS: DestinationFinderQuestions = new Map(
  [
    [
      'temperature',
      {
        icon: 'ThermometerSun',
        question: 'How hot do you want your holiday?',
        answers: new Map([
          ['15', { text: 'Cool - up to 15°C' }],
          ['20', { text: 'Mild - 16°C to 20°C' }],
          ['25', { text: 'Warm - 21°C to 25°C' }],
          ['99', { text: 'Hot - more than 25°C' }],
          ['0', { text: "Don't mind" }],
        ]),
      },
    ],
    [
      'maxFlightTime',
      {
        icon: 'Airplane',
        question: 'How far do you want to travel?',
        answers: new Map([
          ['3', { text: 'Up to 3 hours' }],
          ['6', { text: 'Up to 6 hours' }],
          ['99', { text: 'More than 6 hours' }],
          ['0', { text: "Don't mind" }],
        ]),
      },
    ],
    [
      'budget',
      {
        icon: 'CurrencyPound',
        question: 'How much do you want to spend?',
        answers: new Map([
          ['250', { text: '£250 pp' }],
          ['500', { text: '£500 pp' }],
          ['750', { text: '£750 pp' }],
          ['1000', { text: '£1000 pp' }],
          ['0', { text: 'No max' }],
        ]),
      },
    ],
    [
      'categories',
      {
        icon: 'Sunrise',
        question: 'What type of holiday do you want?',
        info: 'Select any that apply.',
        multiChoice: true,
        answers: new Map([
          ['beach', { text: 'Beach' }],
          ['city', { text: 'City' }],
          ['family', { text: 'Family' }],
          ['romantic', { text: 'Romantic' }],
          ['nightlife', { text: 'Nightlife' }],
          ['luxury', { text: 'Luxury' }],
        ]),
      },
    ],
  ]
);
