import { UtmParamConfigItem, UtmValue } from './types';
import { UTM_COOKIE_DEFAULT_VALUE } from './constants';

export const delay = async (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const parseAdalValue = (adalValue: string): URLSearchParams => {
  return new URLSearchParams(decodeURIComponent(adalValue));
};

export const isUtmValueRecognized = (value: UtmValue): boolean => {
  return !!(value && value !== UTM_COOKIE_DEFAULT_VALUE);
};

export const calculateUtmParamValue = (
  param: UtmParamConfigItem,
  currentCookieValue: string | null,
  queryParams: URLSearchParams,
  adalData: URLSearchParams | null,
  shouldUseAdalData: boolean,
  shouldPrioritiseAdalData: boolean
): string => {
  if (
    shouldPrioritiseAdalData &&
    adalData &&
    adalData.has(param.adalCookieName)
  ) {
    const adalValue = adalData.get(param.adalCookieName);
    if (adalValue) {
      return adalValue;
    }
  }

  if (queryParams && queryParams.has(param.queryParamName)) {
    const queryParamValue = queryParams.get(param.queryParamName);
    if (queryParamValue) {
      return queryParamValue;
    }
  }

  if (isUtmValueRecognized(currentCookieValue) && currentCookieValue) {
    return currentCookieValue;
  }

  if (shouldUseAdalData && adalData && adalData.has(param.adalCookieName)) {
    const adalValue = adalData.get(param.adalCookieName);
    if (adalValue) {
      return adalValue;
    }
  }

  return UTM_COOKIE_DEFAULT_VALUE;
};
