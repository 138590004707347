import { SearchParams } from '../searchResults/types';
import { ConditionalUnitsOptions, Device, Site } from './types';
import { UtmParamsSlice } from '../utmParams/types';
import { addDays } from '../utils/addDays';

export const calculateIsIntervalPassed = (
  previousTimestamp: number,
  currentTimestamp: number,
  interval: number,
  delay = 0
) => {
  return currentTimestamp + delay >= previousTimestamp + interval;
};

export const getClickTripzAdQueryParams = (
  searchParams: SearchParams,
  utmParams: UtmParamsSlice,
  site: Site,
  device: Device,
  cookiesQueryParams: URLSearchParams
): URLSearchParams => {
  const destinationCode = [
    searchParams.destinationCountry,
    searchParams.destinationArea,
    searchParams.destinationRegion,
  ]
    .filter((code) => !!code)
    .join('-');

  const queryParams = new URLSearchParams({
    ...Object.fromEntries(cookiesQueryParams),
    site: site,
    device: device,
    adults: searchParams.adults,
    childAges: searchParams.childAges,
    code: destinationCode,
    departureAirports: searchParams.departureAirports,
    duration: searchParams.duration,
    startDate: searchParams.departureDate,
  });
  if (searchParams.destinationId)
    queryParams.set('placeKey', searchParams.destinationId);
  if (utmParams.campaign) queryParams.set('campaign', utmParams.campaign);
  if (utmParams.content) queryParams.set('content', utmParams.content);
  if (utmParams.source) queryParams.set('source', utmParams.source);
  if (utmParams.medium) queryParams.set('medium', utmParams.medium);

  return queryParams;
};

export const getConditionalUnitsQueryParams = (
  conditionalUnitsOptions: ConditionalUnitsOptions,
  site: Site,
  cookiesQueryParams: URLSearchParams,
  pageQueryParams: URLSearchParams
): URLSearchParams => {
  const conditionalAdUuid = pageQueryParams.get('conditionalAdUuid');
  const params = new URLSearchParams({
    ...Object.fromEntries(cookiesQueryParams),
    site: site,
    device: conditionalUnitsOptions.device,
    ...(conditionalAdUuid ? { uuid: conditionalAdUuid } : {}),
  });

  if (conditionalUnitsOptions.target === 'package_search') {
    const searchParams = conditionalUnitsOptions.searchParams;
    const destinationCode = [
      searchParams.destinationCountry,
      searchParams.destinationArea,
      searchParams.destinationRegion,
    ]
      .filter((code) => !!code)
      .join('-');
    params.set('target', 'package_search');
    params.set(
      'date',
      searchParams.departureDate.split('-').reverse().join('/')
    );
    params.set('departureAirports', searchParams.departureAirports);
    params.set('destination', destinationCode);
    if (searchParams.destinationId) {
      params.set('placeKey', searchParams.destinationId);
    }
  } else if (conditionalUnitsOptions.target === 'car_hire') {
    const searchParams = conditionalUnitsOptions.searchParams;
    params.set('target', 'car_hire');
    params.set('duration', conditionalUnitsOptions.aggregate.duration);
    params.set(
      'date',
      searchParams.pickUpDateTime.split('T')[0].split('-').reverse().join('/')
    );
    params.set('destination', searchParams.pickUpLocationId);
    if (searchParams.age === '33') {
      params.set('minDriverAge', '25');
      params.set('maxDriverAge', '65');
    } else {
      params.set('minDriverAge', searchParams.age);
      params.set('maxDriverAge', searchParams.age);
    }
  }

  return params;
};

export const isoDateToClickTripzDateFormat = (isoDate: string): string => {
  const [yyyy, mm, dd] = isoDate.split('-');
  return [mm, dd, yyyy].join('/');
};

export const getBackTabClickTripzAdvertisersQueryParams = (
  searchParams: SearchParams,
  utmParams: UtmParamsSlice,
  destinationName: string,
  desktopPlacementId: string,
  mobilePlacementId: string,
  device: Device,
  siteId: string
): URLSearchParams => {
  return new URLSearchParams({
    placementId: device === 'mobile' ? mobilePlacementId : desktopPlacementId,
    siteId,
    destination: destinationName,
    trafficSource: [
      utmParams.medium,
      utmParams.source,
      utmParams.campaign,
      utmParams.content,
    ].join('-'),
    startDate: isoDateToClickTripzDateFormat(searchParams.departureDate),
    endDate: isoDateToClickTripzDateFormat(
      addDays(
        new Date(searchParams.departureDate),
        Number(searchParams.duration)
      ).toISOString()
    ),
    adults: searchParams.adults,
  });
};

export const redirectCurrentTab = (nextUrl: string) => {
  window.location.assign(nextUrl);
};
