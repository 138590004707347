import { StateCreator } from 'zustand';
import { Slices } from '../types';
import {
  Destination,
  HolidayDestinationsSlice,
  HolidayDestinationsConfig,
} from './types';
import debounce from 'lodash.debounce';
import { fetchJson } from '../utils/fetchJson';

export const createHolidayDestinationsSlice =
  (
    config: HolidayDestinationsConfig
  ): StateCreator<Slices, [], [], HolidayDestinationsSlice> =>
  (set, get) => ({
    config,
    popularDestinations: [],
    suggestedDestinations: { destinations: [], hotels: [] },
    currentDestination: null,
    isLoadingSuggestedDestinations: false,
    isCurrentDestinationLoaded: false,
    loadPopularDestinations: async (products) => {
      const autocompleteApi = get().holidayDestinations.config.autocompleteApi;
      try {
        const queryParams = new URLSearchParams({
          products: products.join(','),
          limit: '20',
        });
        const destinations = await fetchJson<Destination[]>(
          `${autocompleteApi}/suggest?${queryParams.toString()}`
        );
        set((state) => ({
          ...state,
          holidayDestinations: {
            ...state.holidayDestinations,
            popularDestinations: destinations,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    },
    _fetchSuggestedDestinationDebounced: debounce(async (search, products) => {
      const autocompleteApi = get().holidayDestinations.config.autocompleteApi;
      try {
        const queryParams = new URLSearchParams({
          products: products.join(','),
          limit: '4',
          query: search,
          'group[destinations]': 'place,landmark',
          'group[hotels]': 'accommodation',
        });
        const destinations = await fetchJson<{
          destinations: Destination[];
          hotels: Destination[];
        }>(`${autocompleteApi}/suggest?${queryParams.toString()}`);

        set((state) => ({
          ...state,
          holidayDestinations: {
            ...state.holidayDestinations,
            suggestedDestinations: destinations,
            isLoadingSuggestedDestinations: false,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    }, 500),
    loadSuggestedDestinations: async (search, products) => {
      set((state) => ({
        ...state,
        holidayDestinations: {
          ...state.holidayDestinations,
          isLoadingSuggestedDestinations: true,
        },
      }));
      get().holidayDestinations._fetchSuggestedDestinationDebounced(
        search,
        products
      );
    },
    loadCurrentDestination: async (input) => {
      const autocompleteApi = get().holidayDestinations.config.autocompleteApi;
      const queryParams = new URLSearchParams();

      switch (input.type) {
        case 'worldwide-results': {
          const searchParams = input.searchParams;
          const destinationCodes = searchParams.destinationRegion
            ? `${searchParams.destinationCountry}-${searchParams.destinationArea}-${searchParams.destinationRegion}`
            : `${searchParams.destinationCountry}-${searchParams.destinationArea}`;
          queryParams.set('sghCodes', destinationCodes);
          if (searchParams.destinationId) {
            queryParams.set('ttiPlaceKey', searchParams.destinationId);
          }
          if (searchParams.pinnedAccommodationId) {
            queryParams.set('ttiCode', searchParams.pinnedAccommodationId);
          }
          break;
        }
        case 'destination-finder': {
          if (input.searchParams.destinationCountry) {
            queryParams.set('sgId', input.searchParams.destinationCountry);
          }
          break;
        }
        case 'uk-results': {
          queryParams.set('placeUuid', input.placeUuid);
          break;
        }
      }

      try {
        const destination = await fetchJson<Destination>(
          `${autocompleteApi}/lookup?${queryParams.toString()}`
        );
        set((state) => ({
          ...state,
          holidayDestinations: {
            ...state.holidayDestinations,
            currentDestination: destination,
            isCurrentDestinationLoaded: true,
          },
        }));
      } catch (e) {
        set((state) => ({
          ...state,
          holidayDestinations: {
            ...state.holidayDestinations,
            currentDestination: null,
            isCurrentDestinationLoaded: true,
          },
        }));
      }
    },
  });
