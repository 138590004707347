import { StateCreator } from 'zustand';
import { Slices } from '../types';
import {
  Destination,
  CarHireDestinationsSlice,
  CarHireDestinationsConfig,
} from './types';
import debounce from 'lodash.debounce';
import { fetchJson } from '../utils/fetchJson';

export const createCarHireDestinationsSlice =
  (
    config: CarHireDestinationsConfig | null
  ): StateCreator<Slices, [], [], CarHireDestinationsSlice> =>
  (set, get) => ({
    config,
    suggestedDestinations: [],
    nearbyDestinations: [],
    isLoadingNearbyDestinations: false,
    isLoadingSuggestedDestinations: false,
    _fetchSuggestedDestinationDebounced: debounce(async (search: string) => {
      const config = get().carHireDestinations.config;
      if (!config) return;
      const autocompleteApi = config.autocompleteApi;
      try {
        const { locations } = await fetchJson<{ locations: Destination[] }>(
          `${autocompleteApi}/search/${search}`
        );

        set((state) => ({
          ...state,
          carHireDestinations: {
            ...state.carHireDestinations,
            suggestedDestinations: locations,
            isLoadingSuggestedDestinations: false,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    }, 500),
    loadSuggestedDestinations: async (search: string) => {
      if (search.length <= 2) return;

      set((state) => ({
        ...state,
        carHireDestinations: {
          ...state.carHireDestinations,
          isLoadingSuggestedDestinations: true,
        },
      }));
      get().carHireDestinations._fetchSuggestedDestinationDebounced(search);
    },
    loadNearbyDestinations: async () => {
      const config = get().carHireDestinations.config;
      if (!config) return;

      if (get().carHireDestinations.nearbyDestinations.length > 0) return;
      const autocompleteApi = config.autocompleteApi;

      set((state) => ({
        ...state,
        carHireDestinations: {
          ...state.carHireDestinations,
          isLoadingNearbyDestinations: true,
        },
      }));

      try {
        navigator.geolocation.getCurrentPosition(
          async ({ coords }) => {
            const { locations } = await fetchJson<{ locations: Destination[] }>(
              `${autocompleteApi}/nearest/?lat=${coords.latitude}&lon=${coords.longitude}`
            );
            set((state) => ({
              ...state,
              carHireDestinations: {
                ...state.carHireDestinations,
                nearbyDestinations: locations,
                isLoadingNearbyDestinations: false,
              },
            }));
          },
          () => {
            window.alert(
              'To use this function please allow your browser to use your current location'
            );
            set((state) => ({
              ...state,
              carHireDestinations: {
                ...state.carHireDestinations,
                isLoadingNearbyDestinations: false,
              },
            }));
          }
        );
      } catch (e) {
        console.error(e);
      }
    },
  });
