import { StateCreator } from 'zustand';
import { withPrefix } from 'gatsby';
import { Slices } from '../types';
import {
  SearchResultsSlice,
  OrderByOption,
  OrderByValues,
  SearchResultsConfig,
  FilterData,
  FilterParams,
  ChangeResultsParams,
  ResultItem,
  SearchAlternatives,
  OrderDirection,
} from './types';
import { getQueryParamsWithSessionIdAndPageRequestId } from '../utils/cookies';
import {
  getDepartureDateFromQueryParamsDateString,
  updateSearchParamsWithOriginalValuesFromSearchAlternatives,
  updateSearchParamsWithSearchAlternatives,
} from './utils';

let abortController: AbortController | null = null;

export const createSearchResultsSlice =
  (
    config: SearchResultsConfig
  ): StateCreator<Slices, [], [], SearchResultsSlice> =>
  (set, get) => ({
    config,
    currentPage: 1,
    filterData: [],
    filterIsApplied: false,
    filterParamsForState: {},
    chosenFilterParamsForState: {},
    loaded: true,
    metaUrl: '',
    metaLoading: false,
    orderBy: 'recommended',
    orderDirection: null,
    partners: null,
    pollingCompletion: 10,
    priceMode: 'perperson',
    results: [],
    resultsTotal: null,
    searchParams: null,
    searchAlternatives: null,
    searchedProviders: 0,
    totalDeals: null,
    watchUrls: 0,
    watchUrlsPartnerIds: [],
    isFiltersModalVisible: false,
    isSortingModalVisible: false,
    filtersShowAllLoadingIndicators: false,
    deselectAllFiltersInAllGroups: false,
    setIsFiltersModalVisible: (isVisible) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          isFiltersModalVisible: isVisible,
        },
      }));
    },
    setIsSortingModalVisible: (isVisible) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          isSortingModalVisible: isVisible,
        },
      }));
    },
    setExposedResultsData: (results, partners, totalDeals) =>
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          results: results,
          partners: partners,
          totalDeals: totalDeals,
        },
      })),
    fetchMeta: async (metaUrl: string, updateTotals: boolean) => {
      const config = get().searchResults.config;
      const cookiesQueryParams = getQueryParamsWithSessionIdAndPageRequestId();

      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          metaLoading: true,
        },
      }));
      const data = await (
        await fetch(
          `${config.brokerApi}${metaUrl}&${cookiesQueryParams.toString()}`
        )
      ).json();

      get().searchResults.mergeFilterData(data.filters);

      const totalDeals = updateTotals
        ? data.matchingOffersTotal
        : get().searchResults.totalDeals;
      const resultsTotal = updateTotals
        ? data.matchingResultsTotal
        : get().searchResults.resultsTotal;

      set((state: Slices) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          pollingCompletion: 100,
          metaLoading: false,
          totalDeals: totalDeals,
          resultsTotal: resultsTotal,
        },
      }));
      return data;
    },
    loadSearch: async (device) => {
      const { config, searchParams } = get().searchResults;

      if (!searchParams) return;

      const fetchInitialResults = async (
        brokerApi: string
      ): Promise<
        | {
            watchUrls: string[];
            metaUrl: string;
          }
        | { shouldRetryWithSearchAlternatives: true }
      > => {
        const queryParams = get().searchResults.getOffersApiParams(
          device,
          true
        );
        const response = await fetch(
          `${brokerApi}/v2/packages/search?${queryParams.toString()}`
        );
        const data = await response.json();

        // if it is not an alternative search and if current search will return no results then perform alternative
        if (
          !get().searchResults.searchAlternatives &&
          !data.alternatives.hasFlight
        ) {
          let searchAlternatives: SearchAlternatives | null = null;
          if (data.alternatives.dates.length) {
            searchAlternatives = {
              alternativeDates: data.alternatives.dates,
              originalDate: searchParams.departureDate,
            };
          } else if (data.alternatives.airports.length) {
            searchAlternatives = {
              alternativeAirports: data.alternatives.airports,
              originalAirports: searchParams.departureAirports.split(','),
            };
          }

          if (searchAlternatives) {
            set((state) => ({
              ...state,
              searchResults: {
                ...state.searchResults,
                searchAlternatives,
                searchParams: updateSearchParamsWithSearchAlternatives(
                  searchAlternatives,
                  searchParams
                ),
              },
            }));
            return { shouldRetryWithSearchAlternatives: true };
          }
        }

        if (data.results) {
          set((state) => ({
            ...state,
            searchResults: {
              ...state.searchResults,
              results: data.results,
              partners: data.partners,
            },
          }));
        }
        const urls: string[] = [];
        const watchUrlsPartnerIds: string[] = [];
        if (data.watchUrls) {
          for (const [key, value] of Object.entries(data.watchUrls)) {
            urls.push(value as string);
            watchUrlsPartnerIds.push(key);
          }
        }
        set((state) => ({
          ...state,
          searchResults: {
            ...state.searchResults,
            watchUrls: urls.length,
            watchUrlsPartnerIds: watchUrlsPartnerIds,
            metaUrl: data.metaUrl,
          },
        }));

        return {
          watchUrls: urls,
          metaUrl: data.metaUrl,
        };
      };

      const fetchWatchUrl = async (brokerApi: string, watchUrl: string) => {
        const cookiesQueryParams =
          getQueryParamsWithSessionIdAndPageRequestId();
        const data = await (
          await fetch(
            `${brokerApi}${watchUrl}&${cookiesQueryParams.toString()}`
          )
        ).json();
        set((state) => {
          const newSearchedProviders =
            state.searchResults.searchedProviders + 1;

          return {
            ...state,
            searchResults: {
              ...state.searchResults,
              searchedProviders: newSearchedProviders,
              pollingCompletion:
                Math.ceil(
                  (newSearchedProviders / state.searchResults.watchUrls) * 100
                ) || state.searchResults.pollingCompletion,
              results: data.results
                ? data.results
                : state.searchResults.results,
              partners: data.partners
                ? data.partners
                : state.searchResults.partners,
            },
          };
        });
        return data;
      };

      const initialResults = await fetchInitialResults(config.brokerApi);
      if ('shouldRetryWithSearchAlternatives' in initialResults) {
        // retry search
        return await get().searchResults.loadSearch(device);
      }
      const { watchUrls, metaUrl } = initialResults;
      await Promise.all(
        watchUrls.map(
          async (watchUrl) => await fetchWatchUrl(config.brokerApi, watchUrl)
        )
      );
      await get().searchResults.fetchMeta(metaUrl, true);

      // For no results, set back original values if alternatives were used
      const searchAlternatives = get().searchResults.searchAlternatives;
      if (!get().searchResults.results.length && searchAlternatives) {
        set((state) => ({
          ...state,
          searchResults: {
            ...state.searchResults,
            searchParams:
              updateSearchParamsWithOriginalValuesFromSearchAlternatives(
                searchAlternatives,
                searchParams
              ),
          },
        }));
      }
    },
    mergeFilterData: (newFilterData: FilterData[]) => {
      set((state) => {
        const chosenFilterParamsForState = {
          ...state.searchResults.chosenFilterParamsForState,
        };

        for (const [filterName] of Object.entries(chosenFilterParamsForState)) {
          const lookupBy = filterName === 'price' ? 'name' : 'queryName';
          const matchedFilter = newFilterData.find(
            (item: FilterData) => item[lookupBy] === filterName
          );
          if (matchedFilter && matchedFilter.pricing) {
            matchedFilter.pricing.selectedLowestPrice =
              Number(chosenFilterParamsForState['price'][0]) || undefined;
            matchedFilter.pricing.selectedHighestPrice =
              Number(chosenFilterParamsForState['price'][1]) || undefined;
          }
        }

        for (const filter of newFilterData) {
          if (filter.values && filter.values.some((value) => value.isApplied)) {
            const queryName = filter.queryName;
            const values = filter.values
              .filter((value) => value.isApplied)
              .map((value) => value.value);

            if (queryName) {
              chosenFilterParamsForState[queryName] = values;
            }
          }
        }

        return {
          ...state,
          searchResults: {
            ...state.searchResults,
            filterData: newFilterData,
            chosenFilterParamsForState: chosenFilterParamsForState,
          },
        };
      });
    },
    updateFilters: async (device, updateTotals) => {
      const metaUrl = get().searchResults.metaUrl;
      const queryParams = get().searchResults.getOffersApiParams(device, false);
      const baseMetaUrl = metaUrl.split('?')[0];
      await get().searchResults.fetchMeta(
        `${baseMetaUrl}?${queryParams.toString()}`,
        updateTotals
      );
    },
    changeResults: async (device, params: ChangeResultsParams = {}) => {
      const { scrollToTop = false } = params;
      const config = get().searchResults.config;
      if (!config) return;

      if (abortController) {
        abortController.abort();
      }
      abortController = new AbortController();

      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          loaded: false,
        },
      }));
      if (scrollToTop) {
        window.scrollTo({ top: 0, behavior: 'auto' });
      }
      const brokerApi = get().searchResults.config.brokerApi;
      const queryParams = get().searchResults.getOffersApiParams(device, true);

      try {
        const response = await fetch(
          `${brokerApi}/v2/packages/search?${queryParams.toString()}`,
          { signal: abortController?.signal }
        );
        const data = await response.json();
        if (data.results) {
          set((state) => ({
            ...state,
            searchResults: {
              ...state.searchResults,
              results: data.results,
              partners: data.partners,
              loaded: true,
            },
          }));
        }
      } catch {
        // do nothing
      }
    },
    setOrderBy: (obj: OrderByOption) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          orderBy: obj.filterValue,
          orderDirection: obj.orderDirection,
          currentPage: 1,
        },
      }));
    },
    setCurrentPage: (pageNumber: number) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          currentPage: pageNumber,
        },
      }));
      const pageNumberForUrl = pageNumber === 1 ? [] : [String(pageNumber)];
      get().searchResults.setQueryStringParams('page', pageNumberForUrl);
    },
    setQueryStringParams: (key, values) => {
      const queryParams = new URLSearchParams(window.location.search);
      const allowedKeys = ['pinnedAccommodationId', 'trigger'];

      if (
        Object.keys(values).length === 0 ||
        (values[0] === 'undefined' && values[1] === 'undefined')
      ) {
        if (!allowedKeys.includes(key)) {
          queryParams.delete(key);
        }
        if (key === 'price') {
          queryParams.delete('minPrice');
          queryParams.delete('maxPrice');
        }
      } else {
        queryParams.set(key, values.join('+'));
        if (key === 'price') {
          if (values[0] === 'any') {
            queryParams.delete('minPrice');
          }
          if (values[1] === 'any') {
            queryParams.delete('maxPrice');
          }
          if (values[0] && values[0] !== 'any') {
            queryParams.set('minPrice', values[0]);
          }
          if (values[1] && values[1] !== 'any') {
            queryParams.set('maxPrice', values[1]);
          }
        }
      }

      let newSearch = queryParams.toString().replace(/%2B/g, '+');
      newSearch = newSearch.replace(/undefined/g, 'any');
      const newSearchWithPrefix = newSearch === '' ? '' : `?${newSearch}`;
      const newPath = `${window.location.pathname}${newSearchWithPrefix}${window.location.hash}`;
      window.history.replaceState({}, '', newPath);
    },
    setFilterIsApplied: (filterIsApplied: boolean) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          filterIsApplied,
        },
      }));
    },
    setFilter: (filterParams: FilterParams) => {
      set((state) => {
        let chosenFilterParamsForState = {
          ...state.searchResults.chosenFilterParamsForState,
        };

        if (filterParams.resetAll) {
          chosenFilterParamsForState = {};
        } else if (filterParams.values.length === 0) {
          delete chosenFilterParamsForState[filterParams.queryName];
        } else {
          chosenFilterParamsForState[filterParams.queryName] =
            filterParams.values;
        }

        if (filterParams.queryName === 'price') {
          if (Object.keys(filterParams.values).length === 0) {
            delete chosenFilterParamsForState['minPrice'];
          }
          if (Object.keys(filterParams.values).length === 0) {
            delete chosenFilterParamsForState['maxPrice'];
          }
        }

        return {
          ...state,
          searchResults: {
            ...state.searchResults,
            chosenFilterParamsForState,
          },
        };
      });
      if (
        Object.keys(get().searchResults.chosenFilterParamsForState).length === 0
      ) {
        get().searchResults.setFilterIsApplied(false);
      }
    },
    applyFilter: () => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          filterParamsForState: {
            ...state.searchResults.chosenFilterParamsForState,
          },
          currentPage: 1,
        },
      }));

      for (const [key, value] of Object.entries(
        get().searchResults.chosenFilterParamsForState
      )) {
        get().searchResults.setQueryStringParams(key, value);
      }

      const queryParams = new URLSearchParams(window.location.search);
      const allowedKeys = ['pinnedAccommodationId', 'orderby', 'pricemode'];
      for (const [key] of queryParams.entries()) {
        if (
          !(key in get().searchResults.chosenFilterParamsForState) &&
          !allowedKeys.includes(key)
        ) {
          get().searchResults.setQueryStringParams(key, []);
        }
      }
    },
    generateFilterParamsString: (filtersApplied: boolean) => {
      const filterParams = filtersApplied
        ? get().searchResults.filterParamsForState
        : get().searchResults.chosenFilterParamsForState;

      const filterParamsString = Object.keys(filterParams).reduce(
        (acc, filterName) => {
          const values = filterParams[filterName];
          if (filterName === 'price') {
            const lowestPriceString =
              Number(values[0]) > 0 ? `&minPrice=${values[0]}` : '';
            const highestPriceString =
              Number(values[1]) > 0 ? `&maxPrice=${values[1]}` : '';
            return acc + lowestPriceString + highestPriceString;
          }
          if (values.length > 0) {
            return `${acc}&${filterName}=${values.join(',')}`;
          }
          return acc;
        },
        ''
      );
      return filterParamsString;
    },
    setPriceMode: (priceMode) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          priceMode: priceMode,
        },
      }));
      const priceModeQs = priceMode === 'total' ? ['total'] : [];
      get().searchResults.setQueryStringParams('pricemode', priceModeQs);
    },
    setSearchParamsFromUrl: () => {
      if (typeof window === 'undefined') return null;
      const queryParams = new URLSearchParams(window.location.search);
      const url = window.location.href;
      const urlSplit = url.split('?');
      const urlParts = urlSplit[0].split('/').reverse();
      //Gatsby v5 is adding the trailing slash. Check if it's empty and then remove from the array
      if (urlParts[0] === '') {
        urlParts.shift();
      }
      const [destinationCodesString, destinationId] = urlParts[4].split('+');
      const destinationCodes = destinationCodesString.split('-');
      const destinationRegion =
        destinationCodes.length === 3 ? destinationCodes[2] : null;
      const passengers = urlParts[0];
      const passengersSplit = passengers.split('+');
      const pinnedAccommodationId = queryParams.get('pinnedAccommodationId');

      const obj = {
        duration: urlParts[1],
        departureDate: getDepartureDateFromQueryParamsDateString(urlParts[2]),
        departureAirports: urlParts[3].split('+').join(),
        destinationId: destinationId,
        destinationCountry: destinationCodes[0],
        destinationArea: destinationCodes[1],
        ...(destinationRegion && { destinationRegion: destinationRegion }),
        adults: passengersSplit[0],
        childAges: passengersSplit.splice(1).join(),
        ...(pinnedAccommodationId && { pinnedAccommodationId }),
      };

      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          searchParams: obj,
        },
      }));

      return true;
    },
    getPartner: (id) => {
      const partners = get().searchResults.partners;
      return (partners && partners[id]) || null;
    },
    getSearchResultsPageUrl: (searchParams, boardBases, ratings) => {
      const config = get().searchResults.config;
      const destinationCodes = [
        searchParams.destinationCountry,
        searchParams.destinationArea,
        searchParams.destinationRegion,
      ]
        .filter((value) => !!value)
        .join('-');
      const destinationString = `${destinationCodes}+${
        searchParams.destinationId || ''
      }`;
      const airportsString = searchParams.departureAirports
        .split(',')
        .join('+');
      const dateString = searchParams.departureDate;
      const durationString = searchParams.duration;
      const passengersString = [
        searchParams.adults,
        ...searchParams.childAges.split(','),
      ]
        .filter((value) => !!value)
        .join('+');

      let queryString = '';
      const queryStringArr = [];
      if (searchParams.pinnedAccommodationId) {
        queryStringArr.push(
          `pinnedAccommodationId=${searchParams.pinnedAccommodationId}`
        );
      }
      if (boardBases && boardBases.length) {
        queryStringArr.push(`boardBases=${boardBases.join('+')}`);
      }
      if (ratings && ratings.length) {
        queryStringArr.push(`ratings=${ratings.join('+')}`);
      }

      if (queryStringArr.length > 0) {
        queryString = `?${queryStringArr.join('&')}`;
      }

      return withPrefix(
        `${config.resultsPageUrlStem}/${destinationString}/${airportsString}/${dateString}/${durationString}/${passengersString}${queryString}`
      );
    },
    getInterstitialPageUrl: (partnerId, clickParameters) => {
      const target = encodeURIComponent(clickParameters);
      return withPrefix(
        `${
          get().searchResults.config.interstitialUrl
        }?partnerId=${partnerId}&target=${target}`
      );
    },
    getClickOutUrl: (params) => {
      const target = decodeURI(params);
      const cookiesQueryParams = getQueryParamsWithSessionIdAndPageRequestId();
      return `${
        get().searchResults.config.brokerApi
      }/v1/packages/search/click?${target}&${cookiesQueryParams.toString()}`;
    },
    getPassengerCount: () => {
      return Number(get().searchResults.searchParams?.adults);
    },
    getQueryStringParams: (queryParams) => {
      if (!window.location.search) return queryParams;
      const queryStringParams = new URLSearchParams(window.location.search);
      let updatedSearchResults = {};

      for (const [key, value] of queryStringParams.entries()) {
        switch (key) {
          case 'orderby': {
            const [orderBy, orderDirection] = value.split(' ');
            queryParams.set('orderBy', orderBy);
            queryParams.set('orderDirection', orderDirection);
            updatedSearchResults = {
              ...updatedSearchResults,
              orderBy: orderBy as OrderByValues,
              orderDirection: orderDirection as OrderDirection,
            };
            break;
          }
          case 'page': {
            queryParams.set('page', value);
            updatedSearchResults = {
              ...updatedSearchResults,
              currentPage: Number(value),
            };
            break;
          }
          case 'pricemode': {
            queryParams.set('priceMode', value);
            updatedSearchResults = {
              ...updatedSearchResults,
              priceMode: value,
            };
            break;
          }
          case 'trigger': {
            queryParams.set('trigger', value);
            break;
          }
          default: {
            const commaSeparatedValue = value.replace(/ /g, ',');
            const values = value.split(' ');

            if (key === 'price') {
              if (values[0] !== 'any') {
                queryParams.set('minPrice', values[0]);
              }
              if (values[1] !== 'any') {
                queryParams.set('maxPrice', values[1]);
              }
              get().searchResults.setFilter({
                queryName: key,
                values: values,
              });
            } else {
              queryParams.set(key, commaSeparatedValue);
            }
            updatedSearchResults = {
              ...updatedSearchResults,
              filterIsApplied: true,
            };
            break;
          }
        }
      }

      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          ...updatedSearchResults,
        },
      }));

      return queryParams;
    },
    getOffersApiParams: (device, filtersApplied) => {
      const {
        searchParams,
        orderDirection,
        orderBy,
        currentPage,
        config,
        generateFilterParamsString,
        getQueryStringParams,
      } = get().searchResults;
      const filterQueryParams = new URLSearchParams(
        generateFilterParamsString(filtersApplied)
      );
      if (!searchParams) return new URLSearchParams();
      const queryParams = new URLSearchParams({
        perPage: String(config.resultsPerPage),
        area: searchParams.destinationArea,
        country: searchParams.destinationCountry,
        page: String(currentPage),
        orderBy: orderBy,
        departureDate: searchParams.departureDate,
        duration: searchParams.duration,
        adults: searchParams.adults,
        childAges: searchParams.childAges,
        departureAirports: searchParams.departureAirports,
        partnerOffersPerResult: device === 'desktop' ? '5' : '3',
      });
      if (searchParams.destinationRegion)
        queryParams.set('region', searchParams.destinationRegion);
      if (orderDirection) queryParams.set('orderDirection', orderDirection);
      if (searchParams.destinationId)
        queryParams.set('placeKey', searchParams.destinationId);
      if (searchParams.pinnedAccommodationId)
        queryParams.set(
          'pinnedAccommodationID',
          searchParams.pinnedAccommodationId
        );
      if (filtersApplied) {
        getQueryStringParams(queryParams);
      }
      return new URLSearchParams({
        ...Object.fromEntries(queryParams.entries()),
        ...Object.fromEntries(filterQueryParams.entries()),
        ...Object.fromEntries(getQueryParamsWithSessionIdAndPageRequestId()),
      });
    },
    fetchMoreOffersFromPartner: async (moreOffersUrl) => {
      const config = get().searchResults.config;
      const cookieQueryParams = getQueryParamsWithSessionIdAndPageRequestId();
      const response = await fetch(
        `${config.brokerApi}${moreOffersUrl}&${cookieQueryParams.toString()}`
      );
      const json = await response.json();
      const results: ResultItem[] = json.results;
      return results[0].partnerOffers[0].offers;
    },
    fetchMorePartnerOffers: async (morePartnerOffersUrl: string) => {
      const config = get().searchResults.config;
      const cookieQueryParams = getQueryParamsWithSessionIdAndPageRequestId();
      const response = await fetch(
        `${
          config.brokerApi
        }${morePartnerOffersUrl}&${cookieQueryParams.toString()}`
      );
      const json = await response.json();
      const results: ResultItem[] = json.results;
      return results[0].partnerOffers;
    },
    setDeselectAllFiltersInAllGroups: (deselectAllFiltersInAllGroups) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          deselectAllFiltersInAllGroups,
        },
      }));
    },
    setFiltersShowAllLoadingIndicators: (showAllLoadingIndicators) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          filtersShowAllLoadingIndicators: showAllLoadingIndicators,
        },
      }));
    },
    clearFilters: (device) => {
      get().searchResults.setDeselectAllFiltersInAllGroups(true);
      get().searchResults.setFiltersShowAllLoadingIndicators(true);
      get().searchResults.setFilter({
        queryName: '',
        values: [],
        resetAll: true,
      });
      get().searchResults.applyFilter();
      get().searchResults.updateFilters(device, true);
      get().searchResults.changeResults(device, { scrollToTop: true });
      get().searchResults.setFilterIsApplied(false);
    },
    updateSorting: (sortingOption, device) => {
      get().searchResults.setOrderBy(sortingOption);
      if (sortingOption.filterValue === 'recommended') {
        get().searchResults.setQueryStringParams('orderby', []);
      } else {
        get().searchResults.setQueryStringParams('orderby', [
          sortingOption.filterValue,
          sortingOption.orderDirection || 'desc',
        ]);
      }
      get().searchResults.changeResults(device, { scrollToTop: true });
    },
  });
