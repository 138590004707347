import { ConfigFooter } from '@supersonic/config';

const footer: ConfigFooter = {
  copyright: 'TravelSupermarket Limited',
  logos: ['travelsupermarket'],
  socialLinks: ['facebook', 'instagram', 'twitter', 'tiktok'],
  theme: {
    backgroundColor: 'secondary_darker2',
    borderColor: 'grey6',
    linkColor: 'white',
    linkHoverColor: 'white',
    logosColor: 'white',
    textColor: 'white',
    socialMediaIconColor: 'white',
  },
  secondaryLinks: [
    {
      link: '/en-gb/cookie-policy/',
      text: 'Cookies',
    },
    {
      link: '/en-gb/sitemap/',
      text: 'Site map',
    },
    {
      link: '/en-gb/privacy-policy/',
      text: 'Privacy',
    },
    {
      link: '/en-gb/terms/',
      text: 'Terms and conditions',
    },
  ],
  columns: [
    {
      links: [
        {
          link: '/en-gb/about-us/',
          text: 'About us',
        },
        {
          link: 'https://careers.icetravelgroup.com',
          text: 'Work with us',
        },
        {
          link: '/en-gb/blog/',
          text: 'Blog',
        },
      ],
    },
    {
      links: [
        {
          link: '/en-gb/contact-us/',
          text: 'Contact us',
        },
        {
          link: 'https://ui.awin.com/merchant-profile/8734',
          text: 'Become an affiliate',
        },
      ],
    },
  ],
};
export default footer;
