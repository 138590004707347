import { StateCreator } from 'zustand';
import { Slices } from '../types';
import {
  EmailSubscriptionConfig,
  EmailSubscriptionSlice,
  EmailPreferences,
} from './types';
import { getWasCmpConsentRestored } from './utils';
import { getCookie, setSessionCookie, removeCookie } from '../utils/cookies';
import { showAnchorAds, hideAnchorAds } from '../utils/anchorAds';

export const createEmailSubscriptionSlice =
  (
    config: EmailSubscriptionConfig | null
  ): StateCreator<Slices, [], [], EmailSubscriptionSlice> =>
  (set, get) => ({
    config,
    preferences: null,
    unsubscribeStatus: 'not_requested',
    updatePreferencesStatus: 'not_requested',
    isEmailSubscribed: false,
    isEmailModalVisible: false,
    isEmailSubscriptionError: false,
    init: async () => {
      const config = get().emailSubscription.config;
      if (!config) return;
      const isUserCameFromEmail = get().utmParams.source === 'email';
      if (isUserCameFromEmail) {
        setSessionCookie(config.isEmailSubscribedCookieName, '1');
      }
      const queryParams = new URLSearchParams(window.location.search);
      const disableEmailPopup = queryParams.get('intCrm') === 'false';
      if (disableEmailPopup) {
        setSessionCookie(config.isEmailPopupShownCookieName, '1');
      }

      const isEmailSubscribed = !!getCookie(config.isEmailSubscribedCookieName);
      const isEmailPopupShown = !!getCookie(config.isEmailPopupShownCookieName);
      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          isEmailSubscribed,
        },
      }));
      const wasCmpConsentRestored = await getWasCmpConsentRestored();
      if (wasCmpConsentRestored && !isEmailSubscribed && !isEmailPopupShown) {
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            isEmailModalVisible: true,
          },
        }));
        setSessionCookie(config.isEmailPopupShownCookieName, '1');
      }
    },
    showEmailModal: () => {
      hideAnchorAds();
      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          isEmailModalVisible: true,
        },
      }));
    },
    hideEmailModal: () => {
      showAnchorAds();
      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          isEmailModalVisible: false,
          isEmailSubscriptionError: false,
        },
      }));
    },
    subscribe: async (email) => {
      const config = get().emailSubscription.config;
      if (!config) return;

      try {
        const response = await fetch(
          `${config.emailSubscriptionApi}/subscribe`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email_address: email,
              // TODO: update source once we have other pages except of search results
              source: 'email popup : Sun',
            }),
          }
        );
        if (!response.ok) throw response;
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            isEmailSubscribed: true,
          },
        }));
        setSessionCookie(config.isEmailSubscribedCookieName, '1');
      } catch (e) {
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            isEmailSubscriptionError: true,
          },
        }));
      }
    },
    loadPreferences: async () => {
      const config = get().emailSubscription.config;
      if (!config) return;

      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
        },
      }));

      try {
        const queryParams = new URLSearchParams(window.location.search);
        const userId = queryParams.get('userid');
        const response = await fetch(
          `${config.emailSubscriptionApi}/preferences?userId=${userId}`
        );
        if (!response.ok) throw response;
        const preferences: EmailPreferences = await response.json();
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            preferences,
          },
        }));
      } catch (e) {
        window.location.replace('/');
      }
    },
    updatePreferences: async (preferencesInput) => {
      const config = get().emailSubscription.config;
      if (!config) return;

      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          updatePreferencesStatus: 'loading',
        },
      }));

      try {
        const queryParams = new URLSearchParams(window.location.search);
        const userId = queryParams.get('userid');
        const response = await fetch(
          `${config.emailSubscriptionApi}/preferences?userId=${userId}`,
          {
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(preferencesInput),
          }
        );
        if (!response.ok) throw response;
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            updatePreferencesStatus: 'success',
          },
        }));
      } catch (e) {
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            updatePreferencesStatus: 'error',
          },
        }));
      }
    },
    unsubscribe: async (email) => {
      const config = get().emailSubscription.config;
      if (!config) return;

      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          unsubscribeStatus: 'loading',
        },
      }));

      try {
        const response = await fetch(
          `${config.emailSubscriptionApi}/unsubscribe`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email_address: email,
            }),
          }
        );
        if (!response.ok) throw response;
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            unsubscribeStatus: 'success',
            isEmailSubscribed: false,
          },
        }));
        removeCookie(config.isEmailSubscribedCookieName);
      } catch (e) {
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            unsubscribeStatus: 'error',
          },
        }));
      }
    },
  });
