let anchorAds: NodeListOf<HTMLElement> | null = null;

export const findAnchorAds = () => {
  anchorAds = document.querySelectorAll<HTMLElement>(
    "[id*='-anchor-bottom'][data-anchor-shown='true']"
  );
};

export const showAnchorAds = () => {
  findAnchorAds();
  if (anchorAds && anchorAds.length) {
    anchorAds[0].style.display = 'grid';
  }
};

export const hideAnchorAds = () => {
  findAnchorAds();
  if (anchorAds && anchorAds.length) {
    anchorAds[0].style.display = 'none';
  }
};

export const createAnchorAdSlot = (id: string) => {
  const fixedDivWrapper = document.createElement('div');
  fixedDivWrapper.id = `${id}-wrapper`;
  fixedDivWrapper.dataset.anchorShown = 'true';
  fixedDivWrapper.style.position = 'fixed';
  fixedDivWrapper.style.padding = '4px';
  fixedDivWrapper.style.bottom = '0px';
  fixedDivWrapper.style.width = '100%';
  fixedDivWrapper.style.zIndex = '9999';
  fixedDivWrapper.style.background = 'rgb(250, 250, 250)';
  fixedDivWrapper.style.borderTop = '1px solid #ddd';
  fixedDivWrapper.style.display = 'grid';
  fixedDivWrapper.style.justifyContent = 'center';

  const fixedDiv = document.createElement('div');
  fixedDiv.id = id;

  const fixedDivToggleBtn = document.createElement('button');
  fixedDivToggleBtn.ariaHidden = 'true';
  fixedDivToggleBtn.tabIndex = -1;
  fixedDivToggleBtn.style.position = 'absolute';
  fixedDivToggleBtn.style.bottom = '100%';
  fixedDivToggleBtn.style.left = '0';
  fixedDivToggleBtn.style.padding = '8px 16px';
  fixedDivToggleBtn.style.borderTop = '1px solid #ddd';
  fixedDivToggleBtn.style.borderRight = '1px solid #ddd';
  fixedDivToggleBtn.style.borderRadius = '0 8px 0 0';
  fixedDivToggleBtn.style.background = 'rgb(250, 250, 250)';

  const svg = `
    <svg height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.83 8.83"><defs><style>.cls-1{fill:none;stroke:#616262;stroke-linecap:square;stroke-width:2px}</style></defs><g><path class="cls-1" d="m1.41 1.41 6 6M7.41 7.41l6-6"/></g></svg>`;

  fixedDivToggleBtn.innerHTML = svg;

  fixedDivWrapper.appendChild(fixedDiv);
  fixedDivWrapper.appendChild(fixedDivToggleBtn);

  document.body.appendChild(fixedDivWrapper);

  let isVisible = true;

  const svgElement = fixedDivToggleBtn.querySelector('svg');

  if (!svgElement) return;

  fixedDivToggleBtn.addEventListener('click', () => {
    if (isVisible) {
      fixedDiv.style.display = 'none';
      svgElement.style.transform = 'rotate(180deg)';
    } else {
      fixedDiv.style.display = 'block';
      svgElement.style.transform = 'rotate(0deg)';
    }
    isVisible = !isVisible;
  });
};
