import { AdUnitCode, AdsTargeting } from './types';
import { SearchParams as SearchResultsSearchParams } from '../searchResults/types';
import { SearchParams as DestinationFinderSearchParams } from '../destinationFinder/types';

export const generateAdUnitPath = (
  adUnitCode: AdUnitCode,
  networkCode: string,
  parentAdUnitCode?: string
) => {
  return `/${networkCode}${
    parentAdUnitCode ? `/${parentAdUnitCode}` : ''
  }/${adUnitCode.split('/').join('//')}`;
};

export const mapSearchParamsToTargeting = (
  searchParams: SearchResultsSearchParams | DestinationFinderSearchParams
): AdsTargeting => {
  const [departureYear, departureMonth, departureDay] =
    searchParams.departureDate.split('-');
  return {
    adults: searchParams.adults,
    child_ages: searchParams.childAges
      ? searchParams.childAges.split(',').join(' ')
      : '',
    children: searchParams.childAges
      ? String(searchParams.childAges.split(',').length)
      : '0',
    departureAirports: searchParams.departureAirports.split(',').join(' '),
    duration: searchParams.duration,
    searchDay: departureDay,
    searchMonth: departureMonth,
    searchYear: departureYear,
  };
};
